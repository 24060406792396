<template>
  <div class="order-query">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ title }}</span>
      <span></span>
    </header>
    <main class="page-body">
      <div class="flexbox">
        <van-search
          class="flex-fill"
          v-model="keyword"
          :placeholder="$t('Package.请输入搜索关键词')"
          @search="onSearch"
        />
      </div>
      <section v-if="list.length > 0">
        <section class="list padding" :style="[bodyHeight]">
          <div class="card" v-for="(item, index) in list" :key="index">
            <template>
              <div
                class="card-body flexbox space-bt"
                @click="
                  if (item.istatus <= 0) {
                    $router.push({
                      name: 'PackageEdit',
                      query: { id: item.PK_ID },
                    });
                  }
                "
              >
                <div>
                  <div>
                    {{ $t('Package.快递单号') }}：{{ item.delivery_no | empty }}
                  </div>
                  <div>
                    {{ $t('Package.唛头') }}：{{ item.sShippingMark | empty }}
                  </div>
                  <div>{{ $t('Package.箱号') }}：{{ item.sBagNo | empty }}</div>
                  <div>
                    {{ $t('Package.进仓时间') }}：{{ item.createtime | empty }}
                  </div>
                  <div>{{ $t('Package.物品名称') }}：{{ item.goods_name }}</div>
                  <span
                    >{{ $t('Package.进仓编号') }}：{{
                      item.sCode | empty
                    }}</span
                  >
                  <div>
                    {{ $t('Package.订单编号') }}：{{ item.OrderNo | empty }}
                  </div>
                  <div>
                    {{ $t('Package.单个货值') }}：{{
                      item.single_price | empty
                    }}
                  </div>
                  <div>
                    {{ $t('Package.整体货值') }}：{{
                      item.fDeclareAmount_LC | empty
                    }}
                  </div>
                </div>
                <img
                  @click="onImgPreview(item.sPicUrl)"
                  :src="
                    item.sPicUrl == '' ? emptyImg : tms_img_url + item.sPicUrl
                  "
                  alt=""
                  :class="item.sPicUrl == '' ? 'noimg' : 'img'"
                />
              </div>
              <footer class="card-footer">
                {{$t('Package.箱数')}}：<span class="foot-desc">{{ item.iBags }}</span>
                <span class="pull-right">
                  {{$t('Package.状态')}}：<span class="round-label">{{
                    pkgMap[item.istatus]
                  }}</span>
                </span>
              </footer>
            </template>
          </div>
        </section>
        <div class="pagination">
          <van-pagination
            @change="onSearch"
            v-model="currentPage"
            :total-items="totalCount"
            :items-per-page="limit"
            :show-page-size="4"
            force-ellipses
          />
        </div>
      </section>
      <section v-else class="padding no-item">
        <div class=""><img :src="emptyImg" /></div>
        <div class="nodesc">{{$t('Package.暂时没有记录')}}</div>
      </section>
    </main>
  </div>
</template>
<script>
import { getPackageList } from '@/api/deliver.js';
import { BASE_TMS_URL } from '@/api/index.js';
import { $t } from '@/plugins/i18n';
import { getValidTime } from '@/utils/index.js';
import { ImagePreview } from 'vant';

export default {
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keyword: '',
      list: [],
      emptyImg: require('../../assets/images/noinfo.png'),
      userInfo: this.$store.getters.userInfo,
      tms_img_url: BASE_TMS_URL,
      totalCount: 0,
      currentPage: 1,
      limit: 15,
      state: -100,
      bodyHeight: { height: '644px', overflow: 'auto' },
      pkgMap: {
        '-1': $t('Package.待入库'),
        0: $t('Package.已入库'),
        1: $t('Package.已发货'),
        2: $t('Package.待认领'),
        3: $t('Package.问题件'),
        '-10': $t('Package.全部包裹'),
      },
    };
  },
  computed: {
    title() {
      const q = this.$route.query;
      if (q.type === 'package') {
        let sta = q.status == 'outstock' ? -1 : q.status;
        return `${$t('Package.我的包裹')}-${this.pkgMap[sta]}`;
      }
      return $t('Package.我的包裹');
    },
  },
  mounted() {
    let hei = window.innerHeight - 176;
    this.bodyHeight.height = hei + 'px';
    this.onSearch();
  },
  methods: {
    onSearch() {
      const q = this.$route.query;
      var sta = q.status;
      sta = sta == 'outstock' ? -1 : sta;
      let offcount = (this.currentPage - 1) * this.limit;
      getPackageList({
        keyword: this.keyword,
        state: sta,
        limit: this.limit,
        offset: offcount,
      }).then(res => {
        res.rows.forEach(it => {
          it.createtime = getValidTime(it.createtime);
        });
        this.list = res.rows;
        this.totalCount = res.total;
      });
    },
    onImgPreview(imgurl) {
      if (imgurl == '' || imgurl == null) {
        return;
      }
      ImagePreview([imgurl]);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.card {
  padding: rem(20px);
  padding-top: 0;
  border-radius: rem(15px);
  box-shadow: 0px 2px 10px 0px rgba(14, 48, 53, 0.1);
  background-color: #fff;
  .card-header {
    color: #b8b8b8;
    font-size: 12px;
    border-bottom: 1px solid #efefef;
    height: rem(53px);
    line-height: rem(53px);
  }
  .card-body {
    padding: rem(20px) 0;
    border-bottom: 1px solid #efefef;
    line-height: rem(52px);
  }
  .card-footer {
    color: #666;
    padding-top: rem(20px);
    height: rem(30px);
  }
  & + .card {
    margin-top: rem(20px);
  }
  .foot-desc {
    font-size: rem(32px);
    border-color: #673ab7;
    border: 1px solid;
    color: #673ab7;
    padding: 0 10px;
    border-radius: 4px;
    margin-right: 0.26rem;
  }
}
.padding {
  padding: rem(20px);
}
.img {
  width: rem(280px);
  height: rem(150px);
}
.noimg {
  width: auto;
  height: rem(150px);
}

.no-item {
  text-align: center;
  margin: rem(40px);
  img {
    height: rem(249px);
  }
  .no-desc {
    font-size: rem(13px);
    text-align: center;
  }
}

.round-label {
  background: #b9aebb;
  color: #fff;
  padding: 3px;
  border-radius: 3px;
  margin: 0 5px;
}
.order-info-item {
  border-color: #b9aebb;
  border: 1px solid;
  color: #b9aebb;
  padding: 0px 10px;
  border-radius: 4px;
  margin-right: rem(20px);
}
</style>
